@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/lightmode';
@use '@sats-group/ui-lib/tokens/corner-radius';
@use '@sats-group/ui-lib/tokens/elevation';

.session-options {
  $breakpoint: 600px;

  &__confirmation {
    display: none;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 420px;
    z-index: 500;
    width: calc(100% - #{spacing.$s * 2});

    &--open {
      display: block;
    }
  }

  &__confirmation-text {
    margin-bottom: spacing.$l;
    > * + * {
      margin-bottom: spacing.$l;
    }
  }

  &__confirmation-buttons {
    display: flex;
    gap: spacing.$xs;

    > * {
      flex: 1 1 0;
    }
  }

  &__confirm {
    width: 100%;
  }

  &__confirmation-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: lightmode.$on-background-primary;
    opacity: 0.2;
    z-index: 499;
  }

  &__content {
    @include elevation.level(1);
    width: 100%;
    border-radius: corner-radius.$s;
    background: lightmode.$background-secondary;
    padding: spacing.$xl spacing.$l;
  }

  &__list {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
  }

  &__list-item {
    list-style-type: none;

    &:not(:last-of-type) {
      border-bottom: 1px solid lightmode.$ui-border;
    }
  }

  &__item {
    display: block;
    border: 0;
    background: none;
    text-align: left;
    margin: 0;
    cursor: pointer;
    padding: spacing.$m 0;
    text-decoration: none;
    font-size: inherit;
    color: inherit;
  }
}
